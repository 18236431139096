import React from "react"
import { FormattedMessage } from "react-intl"
import SubLayout from "../components/Sublayout"
import { LocaleStoreContext } from "../components/LocaleStore"

import Container from "../components/structural/Container"
import styled from "styled-components"
import BetterPlaceDonateForm from "../components/BetterPlaceDonateForm"

const Card = styled.div`
  max-width: 900px;
  width: calc(90% - 32px);
  background-color: white;
  border-radius: 10px;
  margin: auto;
  align-items: center;
  padding: 32px;
  text-align: center;

  .accinfo {
    width: 100%;
  }
`

export default function Donate() {
  return (
    <SubLayout fixedHeader>
      <Container heading="Donate.heading">
        <Card>
          <div className="text-center">
            <LocaleStoreContext.Consumer>
              {(context) => <BetterPlaceDonateForm lang={context.locale} />}
            </LocaleStoreContext.Consumer>
          </div>
          <p>
            <FormattedMessage id="Donate.p" />
          </p>
          <p className="accinfo">
            <span>
              <FormattedMessage id="Donate.holder" />
            </span>
            : Kanya´s Chance e.V.
            <br />
            IBAN: DE58 3006 0601 0005 5060 95
            <br />
            BIC: DAAEDEDDXXX
          </p>
          <p>
            <FormattedMessage id="Donate.info" />
          </p>
        </Card>
      </Container>
    </SubLayout>
  )
}
