import React, { useEffect } from "react"

export default function BetterPlaceDonateForm(props) {
  useEffect(() => {
    window._bp_iframe = document.getElementById("betterplace_donation_iframe")
    window._bp_iframe.project_id = 69358 /* REQUIRED */
    window._bp_iframe.lang = props.lang /* Language of the form */
    window._bp_iframe.width = 600 /* Custom iframe-tag-width, integer */
    window._bp_iframe.color =
      "fec110" /* Button and banderole color, hex without "#" */
    window._bp_iframe.background_color =
      "ffffff" /* Background-color, hex without "#" */
    window._bp_iframe.default_amount = 20 /* Donation-amount, integer 1-99 */
    window._bp_iframe.recurring_interval =
      "single" /* Interval for recurring donations, string out of single, monthly und yearly */
    window._bp_iframe.bottom_logo = true

    const script = document.createElement("script")
    script.src =
      "https://betterplace-assets.betterplace.org/assets/load_donation_iframe.js"
    script.async = true
    script.type = "text/javascript"
    script.onLoad = () => {
      console.log("loaded")
    }
    document.body.appendChild(script)
  })
  return (
    <div>
      <div
        id="betterplace_donation_iframe"
        style={{
          background:
            "transparent url('https://www.betterplace.org/assets/new_spinner.gif') 275px 20px no-repeat",
          minHeight: 300,
        }}
      ></div>
    </div>
  )
}
